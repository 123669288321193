@import "./layout";

$phone: 800px;
$tablet: 1000px;
$fullHD: $widthMax;

@mixin phone {
	@media (max-width: #{$phone}) {
		@content; } }

@mixin tablet {
	@media (min-width: #{$phone + 1}) and (max-width: #{$tablet}) {
		@content; } }

@mixin notFullHD {
	@media (max-width: #{$fullHD - 1}) {
		@content; } }
@mixin fullHD {
	@media (min-width: #{$fullHD}) {
		@content; } }


@mixin truncate($regular, $max) {
	@include notFullHD {
		 #{$regular} {} }
	@include fullHD {
		#{$max} {} } }

