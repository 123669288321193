@import "../../../assets/styles/colors";
.root {
	position: relative; }

.thumb {
	position: relative;
	padding-top: 50%; }

.--title-color-theme .title {
	color: $themeColor; }

.--title-text-align-center .title {
	text-align: center; }
.--title-text-align-justify .title {
	text-align: justify; }
.--summary-text-align-center .summary {
	text-align: center; }
.--summary-text-align-justify .summary {
	text-align: justify; }
