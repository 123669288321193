@import "../../../assets/styles/colors";
@import "../../../assets/styles/layout";

.button {
	appearance: none;
	border-radius: 0;
	padding: 6px 10px;
	height: 34px;
	box-sizing: border-box;
	border: 1px solid $actionColor;
	background: transparent;
	color: $actionColor;
	outline: none;
	font-weight: bold;
	text-overflow: ellipsis;
	text-transform: uppercase;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;

	&:hover {
		background: $actionColor;
		color: $actionContrastColor; } }

.menu {
	font-family: $menuLinksFont; }

.primary {
	background: $actionColor;
	color: $actionContrastColor;

	&:hover {
		background: $actionContrastColor;
		color: $actionColor; } }
