@import "../../assets/styles/colors";

.root {
	position: fixed;
	transition: transform 200ms linear;

	form {
		padding: 1em; }

	label {
		position: relative;
		cursor: pointer; } }

.content {
	display: flex;
	flex-direction: column; }

.form-container {
	flex: 1 1 auto;
	overflow-y: auto;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch; }

.text-input,
.text-area {
	appearance: none;
	outline: none;
	width: 100%;
	background: $backgroundColorAlternate;
	border: 1px solid transparent;
	box-sizing: border-box;
	border-radius: 0;
	color: $strongTextColor;

	&:focus {
		border-color: $actionColor; } }

.text-input {
	height: 34px;
	text-indent: 10px; }

.text-area {
	height: 68px;
	min-height: 68px;
	padding: 10px;
	resize: vertical; }

.label {
	display: block;
	padding: 8px 10px;
	color: $textColor; }

.horizontal-options {
	display: flex;
	justify-content: space-around;

	> * {
		flex: 0 1 120px;
		position: relative;
		text-align: center;
		margin: 10px; } }

.options-list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;

	> * {
		flex: 1 1 30%;
		position: relative;
		text-align: center;
		margin: 10px; } }

.is-submitted {
	transform: translateY(-200vh); }

.icon {
	position: relative;
	width: 100%;
	height: 100%;
	max-height: 60px;
	max-width: 60px;
	margin: auto;

	* {
		fill: $textColor; } }

.radio {
	position: relative;

	input {
		position: absolute;
		opacity: 0;
		visibility: hidden; }

	input + * {
		pointer-events: none;
		border: 1px solid $textColor;

		.checkmark {
			position: absolute;
			left: 6px;
			top: 6px;
			width: 14px;
			height: 14px;
			visibility: hidden; } }

	input:checked + * {
		border-color: $actionColor;

		.checkmark {
			visibility: visible; }

		.label {
			color: $strongTextColor; }

		.icon * {
			fill: $actionColor; } } }

.checkbox {
	display: block;

	input {
		position: absolute;
		opacity: 0;
		visibility: hidden; }

	input + * {
		pointer-events: none;
		border: 1px solid $textColor;
		padding: 6px 10px;
		user-select: none;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		svg {
			opacity: 0;
			width: 14px;
			height: 14px;
			margin-right: 10px;

			* {
				fill: $textColor; } } }

	input:checked + * {
		color: $strongTextColor;
		border-color: $actionColor;

		svg {
			opacity: 1;

			* {
				fill: $actionColor; } } } }

.simple-checkbox {
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	input {
		position: absolute;
		opacity: 0;
		visibility: hidden; }
	input + * {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		pointer-events: none;
		user-select: none;
		.checkbox-icon {
			position: relative;
			border: 1px solid $textColor;
			width: 16px;
			height: 16px;
			margin-right: 10px;

			svg {
				position: absolute;
				top: 1px;
				left: 1px;
				width: 14px;
				height: 14px;
				opacity: 0;
				* {
					fill: $actionColor; } } } }
	input:checked + * {
		color: $strongTextColor;
		.checkbox-icon {
			border-color: $actionColor;
			svg {
				opacity: 1; } } } }

.header {
	display: flex;
	justify-content: space-between;
	padding: 10px 1em;
	z-index: 1;

	h3 {
		margin: 0; } }

.close-button {
	appearance: none;
	padding: 4px 10px;
	height: 24px;

	border-radius: 20px;
	background: rgba(255, 255, 255, 0.1);
	border: none;
	color: $strongTextColor;

	text-transform: uppercase;
	cursor: pointer;
	font-size: x-small;

	&:hover {
		background: rgba(255, 255, 255, 0.2); }

	* {
		pointer-events: none; } }

.error-message {
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px;
	color: $errorColor;
	background-color: $errorBackgroundColor; }

.submit-container {
	display: flex;
	justify-content: center;
	padding: 20px 0; }
