@import "../../../assets/styles/colors";
@import "../../../assets/styles/layout";

.button {
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: space-between;

	border-radius: 0;
	padding: 5px 10px;
	height: 34px;
	box-sizing: border-box;
	border: 1px solid $actionColor;
	background: transparent;
	color: $actionColor;
	outline: none;
	font-weight: bold;
	text-transform: uppercase;

	cursor: pointer;

	> *:last-child {
		flex: 0 0 10px;
		margin-left: 6px;
		margin-top: 2px; }

	> *:first-child {
		flex: 1 1 auto;
		min-width: 0;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap; }

	svg {
		fill: $actionColor; }

	&:hover {
		background: $actionColor;
		color: $actionContrastColor;

		svg {
			fill: $actionContrastColor; } } }

.menu {
	font-family: $menuLinksFont; }

.menu-link {
	font-family: $menuLinksFont;
	background: transparent;
	color: $menuTextColor;
	border: none;

	// font-weight: normal
	text-transform: uppercase;

	svg {
		fill: $menuTextColor; }

	&:hover {
		background: transparent;
		border-bottom-color: $actionColor;
		color: $actionColor;
		svg {
			fill: $actionColor; } } }




.primary {
	background: $actionColor;
	color: $actionContrastColor;

	svg {
		fill: $actionContrastColor; }

	&:hover {
		background: $actionContrastColor;
		color: $actionColor;
		svg {
			fill: $actionColor; } } }

.options {
	position: absolute;
	background: $actionColor;
	border: 1px solid $actionColor;
	max-width: 50vw; }

.option {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 !important;
	padding: 4px 10px;
	height: 34px;
	box-sizing: border-box;
	color: $actionContrastColor;
	cursor: pointer;
	> * {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 100%; }

	&.is-selected {
		background-color: rgba($actionContrastColor, 0.1); } }
