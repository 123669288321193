@import "../../../assets/styles/colors";
@import "../../../assets/styles/mixins";

.root {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;

	> *:first-child {
		flex: 0 0 140px;
		min-width: 80px;
		height: 100%; }

	@include phone {
		> *:first-child {
			flex: 0 0 80px; } } }

.image-container {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 100%;
	padding-top: 75%;
	box-sizing: border-box; }

.details {
	.title {
		margin-top: 0.5em;
		margin-bottom: 0.5em; }
	.summary {
		position: relative;
		font-size: small;
		margin-bottom: 0.5em;
		> .text {
			padding-right: 80px;
			display: inline-block;
			line-height: $textLineHeight;
			max-height: calc(2*#{$textLineHeight});
			overflow: hidden; } }

	.link {
		white-space: nowrap;
		position: absolute;
		right: 0;
		top: $textLineHeight;
		color: $actionColor; }

	@include notFullHD {
		padding-left: calc(#{$horizontalSpacing} / #{$phi});
		padding-right: calc(#{$horizontalSpacing} / #{$phi}); }


	@include fullHD {
		padding-left: calc(#{$horizontalSpacingMax} / #{$phi});
		padding-right: calc(#{$horizontalSpacingMax} / #{$phi}); } }
