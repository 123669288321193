@import '../../assets/styles/layout';
@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.root > * {
	display: flex; }

.info-bar {
	display: flex;
	justify-content: space-between;
	padding: 20px 0;
	margin-top: calc(#{$verticalSpacing} / #{$phi}); }

.logo-container {
	@include notFullHD {
		margin-right: calc(#{$horizontalSpacing} * #{$phi}); }

	@include fullHD {
		margin-right: calc(#{$horizontalSpacingMax} * #{$phi}); }

	h4 {
		margin-bottom: 4em; } }

.logo {
	position: relative;
	padding-top: 25%;
	min-width: 100px;
	> * {
		position: absolute; } }

.details > *:last-child {
	flex: 1 1 auto; }

.inner-title {
	margin-top: 0; }

.links-container {
	p {
		padding: 4px 0;
		margin: 0; }
	a {
		color: $textColor;
		text-decoration: none; } }

.external-links {
	display: flex;
	flex-wrap: wrap;
	> * {
		flex: 0 0 calc(50% - 40px); } }

.social-links {
	display: flex;
	flex-wrap: wrap;

	.social-icon {
		margin: 10px;
		width: 32px;
		height: 32px; } }

@include phone {
	.root > *:not(.info-bar) {
		flex-direction: column; }

	.logo-container {
		width: 100%;
		text-align: left;
		.logo {
			max-width: 180px;
			margin-left: 0;
			margin-right: auto;
			padding-left: calc(#{$horizontalSpacing} * #{$phi});
			padding-rigth: calc(#{$horizontalSpacing} * #{$phi}); } } }

