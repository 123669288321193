@import "../../assets/styles/colors";

@keyframes blink {
	0% {
		opacity: 0; }

	50% {
		opacity: 0; }

	80% {
		opacity: 0.6; }

	100% {
		opacity: 0; } }

@keyframes scroll {
	0% {
		top: 10px;
		bottom: 24px;
		opacity: 0; }

	50% {
		top: 10px;
		bottom: 24px;
		opacity: 0; }

	70% {
		top: 10px;
		bottom: 24px; }

	80% {
		top: 10px;
		bottom: 10px;
		opacity: 0.6; }

	90% {
		top: 30px;
		bottom: 10px; }

	100% {
		top: 30px;
		bottom: 10px;
		opacity: 0; } }

.mouse {
	position: relative;
	display: inline-block;
	width: 24px;
	height: 40px;
	margin-bottom: 10px;
	cursor: pointer; }

.border {
	display: inline-block;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 2px solid $strongTextColor;
	border-radius: 12px;
	opacity: 1;
	animation: blink 4s ease-out infinite; }

.scroll {
	position: absolute;
	display: inline-block;
	top: 10px;
	bottom: 34px;
	left: 50%;
	margin-left: -1px;
	width: 2px;
	border-radius: 1px;
	background: $strongTextColor;
	animation: scroll 4s ease-out infinite; }
