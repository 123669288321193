.root {
	> * {
		transition: opacity 400ms linear, transform 400ms linear;
		opacity: 1;
		transform: translateY(0); }
	&.hidden > * {
		transition: none;
		opacity: 0;
		transform: translateY(25%); } }

