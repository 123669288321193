@import "../../assets/styles/mixins";
@import "../../assets/styles/layout";
@import "../../assets/styles/colors";

.thumb-title {
	a {
		display: block;
		padding: 0.5em 0;
		color: $textColor;
		text-decoration: none; }

	&.is-selected a {
		color: $strongTextColor; } }

.slide-title {
	margin-top: 0; }

.root.--text-shadow .slide-text {
	text-shadow: 1px 1px 0px black, 2px 2px 0px black; }

@keyframes grow {
	from {
		width: 0; }
	to {
		width: 100%; } }

.slide-number {
	position: relative;
	padding: 0;
	display: flex;
	align-items: center;
	margin: 0 0 0.5em;

	> * {
		margin: 0 0 0.5em; }

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 100%;
		width: 100%;
		height: 1px;
		background: $textColor;
		opacity: 0.8; }

	&::after {
		content: "";
		display: block;
		position: absolute;
		top: 100%;
		width: 0;
		height: 1px;
		background: $actionColor; }

	&.is-animating::after {
		animation: grow 5s ease-out forwards;
		animation-duration: inherit; } }
