$backgroundColor: #282828;
$backgroundColorAlternate: #242424;
$backgroundColorDarker: #121212;
$foregroundColor: #303030;
$backgroundColorFooter: #1C1C1C;
$backgroundColorFooterDarker: #121212;

$textColor: #BBBBBB;
$titlesColor: #FFFFFF;
$strongTextColor: #FFFFFF;
$actionColor: #E2B562;
$actionContrastColor: #000000;
$themeColor: #E2B562;

$menuBackgroundColor: #343434;
$menuTextColor: #AAAAAA;
$menuTextColorSelected: #E2B562;

$errorColor: #000000;
$errorBackgroundColor: #CF6679;
