$fonts: "Merriweather+Sans|Oswald";
$bodyFont: "Merriweather Sans";
$titlesFont: "Oswald";
$menuLinksFont: "Oswald";

$textLineHeight: 1.6em;

$horizontalSpacingPercentage: 4;
$widthMax: 1600px;
$horizontalSpacing: #{$horizontalSpacingPercentage}vw;
$horizontalSpacingMax: $widthMax * $horizontalSpacingPercentage / 100;

$verticalSpacing: calc(2em + 4vh);

$menuHeight: 80px;
$mobileMenuHeight: 50px;

$h1Factor: 2.8;
$h1GrowFactor: #{$h1Factor}vw;
$h1GrowFactorMax: $widthMax * $h1Factor / 100;

$h2Factor: 1.2;
$h2GrowFactor: #{$h2Factor}vw;
$h2GrowFactorMax: $widthMax * $h2Factor / 100;

$phi: 1.618;
