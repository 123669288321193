.picture {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	background-repeat: no-repeat;
	background-position: center;
	background-size: contain; }

.picture.--image-size-cover {
	background-size: cover; }

.picture.--image-position-center {
	background-position: center; }

.picture.--image-position-center-top {
	background-position: center top; }

.picture.--image-position-center-bottom {
	background-position: center bottom; }

.picture.--image-position-left-center {
	background-position: left center; }

.picture.--image-position-left-top {
	background-position: left top; }

.picture.--image-position-left-bottom {
	background-position: left bottom; }

.picture.--image-position-right-center {
	background-position: right center; }

.picture.--image-position-right-top {
	background-position: right top; }

.picture.--image-position-right-bottom {
	background-position: right bottom; }
