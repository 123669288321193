@import "../../assets/styles/mixins";
@import "../../assets/styles/layout";

@mixin card($nCols: 4, $nColsPhone: 2, $hGap: calc(#{$horizontalSpacing}/#{$phi}), $vGap: calc(#{$verticalSpacing}/#{$phi}), $hGapMax: calc(#{$horizontalSpacingMax}/#{$phi})) {
	flex-basis: calc(100% / #{$nCols} - #{$hGap});
	margin-left: calc(#{$hGap}/2);
	margin-right: calc(#{$hGap}/2);
	margin-top: calc(#{$vGap}/2);
	margin-bottom: calc(#{$vGap}/2);

	@include fullHD {
		flex-basis: calc(100% / #{$nCols} - #{$hGapMax});
		margin-left: calc(#{$hGapMax}/2);
		margin-right: calc(#{$hGapMax}/2); }


	@include phone {
		flex-basis: calc(100% / #{$nColsPhone} - #{$hGap}); } }

.grid-content {
	display: flex;
	flex-wrap: wrap;

	> * {
		box-sizing: border-box; } }

.root:not(.--grid-gap-big) .grid-content {
	margin-left: calc(#{$horizontalSpacing}/#{$phi}/-2);
	margin-right: calc(#{$horizontalSpacing}/#{$phi}/-2);
	margin-top: calc(#{$verticalSpacing}/#{$phi}/-2);
	height: calc(100% - #{$verticalSpacing}/#{$phi}/2); }

.root:not(.--grid-size-tiny),
.root:not(.--grid-size-medium),
.root:not(.--grid-size-large) {
	.grid-content > * {
		@include card(4); } }

.root.--grid-size-tiny {
	.grid-content > * {
		@include card(8, 3); } }

.root.--grid-size-medium {
	.grid-content > * {
		@include card(3, 1, $horizontalSpacing, $verticalSpacing, $horizontalSpacingMax); } }

.root.--grid-size-large {
	.grid-content > * {
		@include card(2, 1, $horizontalSpacing, $verticalSpacing, $horizontalSpacingMax); } }

.root.--grip-gap-big {
	.grid-content {
		margin-left: calc(#{$horizontalSpacing}/-2);
		margin-right: calc(#{$horizontalSpacing}/-2);
		margin-top: calc(#{$verticalSpacing}/-2);
		height: calc(100% - #{$verticalSpacing}/2); }

	&:not(.--grid-size-tiny),
	&:not(.--grid-size-medium),
	&:not(.--grid-size-large) {
		.grid-content > * {
			@include card(4, 2, $horizontalSpacing, $verticalSpacing, $horizontalSpacingMax); } }

	&.--grid-size-tiny {
		.grid-content > * {
			@include card(6, 3, $horizontalSpacing, $verticalSpacing, $horizontalSpacingMax); } }

	&.--grid-size-medium {
		.grid-content > * {
			@include card(3, 1, $horizontalSpacing, $verticalSpacing, $horizontalSpacingMax); } }

	&.--grid-size-large {
		.grid-content > * {
			@include card(2, 1, $horizontalSpacing, $verticalSpacing, $horizontalSpacingMax); } } }
