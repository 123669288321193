@import "assets/styles/colors";

.no-scroll {
	overflow: hidden; }

.app {
	background: $backgroundColor;
	> * {
		will-change: filter; } }

.is-pushed-back {
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 4px;

	transition: transform 200ms ease-out, filter 200ms linear;
	transform-origin: bottom center;
	transform: scale(0.97, 0.97);

	> * {
		filter: blur(4px);

		> * {
			background: $backgroundColor; } }

	.main {
		max-height: 120vh; } }

.main {
	position: relative;
	z-index: 0; }

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.2); }

@keyframes appear-from-bottom {
	from {
		transform: translateY(100%); }

	to {
		transform: translateY(0%); } }

.contact-us-window {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	overflow: hidden;
	pointer-events: none;

	> * {
		pointer-events: all;
		max-width: 800px;
		max-height: 88%;
		width: 98%;
		background: $foregroundColor;
		border: 1px solid rgba(255, 255, 255, 0.05);
		border-radius: 4px 4px 0 0;
		border-bottom-width: 0;
		transform: translateY(100%);
		animation: appear-from-bottom 200ms ease-out 200ms forwards; } }

.title-label {
	margin-top: 1em;
	margin-bottom: 0.5em;
	+ p {
		margin-top: 0; } }


.section-title {
	position: relative;
	display: inline;

	&::after {
		display: block;
		content: "";
		position: absolute;
		top: calc(100% + 0.3em);
		left: 0;
		right: 0;
		height: 1px;
		background: $themeColor; }

	+ * {
		margin-top: 3em; } }
