@import "./assets/styles/layout";
@import "./assets/styles/colors";
@import "./assets/styles/mixins";
@import url('https://fonts.googleapis.com/css?family=#{$fonts}&display=block');

body {
	margin: 0;
	font-family: $bodyFont;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: $backgroundColorDarker;
	color: $textColor; }

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

a {
	color: $strongTextColor; }

strong,
b {
	color: $strongTextColor;
	font-weight: normal; }

h1, h2, h3, h4, h5, h6 {
	font-family: $titlesFont;
	color: $titlesColor;
	font-weight: bold; }

h1 {
	@include notFullHD {
		font-size: calc(2.2em + #{$h1GrowFactor}); }
	@include fullHD {
		font-size: calc(2.2em + #{$h1GrowFactorMax}); } }

p {
	line-height: $textLineHeight; }

button {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

input,
textarea {
	font-family: $bodyFont;
	font-size: 16px;
	&::placeholder {
		font-size: 0.8em; } }

.icon {
	width: 14px;
	height: 14px;
	fill: $textColor; }

#modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99;
	&:empty {
		pointer-events: none; } }
