@import "../../assets/styles/layout";
@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.root {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	box-sizing: border-box;

 }	//width: 100%

.content {
	flex: 1 1 100%;
	display: flex;
	flex-direction: column; }

.cardholder {
	display: flex;
	box-sizing: border-box;

	&.stretch > * {
		flex-grow: 1; }

	// &.vertical-stretch
 }	// 	height: 100%

.with-aspect-ratio {
	position: relative;

	> * {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0; } }

.row {
	flex-direction: row; }

.row-reverse {
	flex-direction: row-reverse; }

.column {
	flex-direction: column; }

.column-reverse {
	flex-direction: column-reverse; }

// Modifiers
.root.--root-width-normal .content {
	width: 100%;
	max-width: $widthMax;
	margin-left: auto;
	margin-right: auto; }

.root.--root-background-darker {
	background-color: $backgroundColorDarker; }

.root.--root-background-alternate {
	background-color: $backgroundColorAlternate; }

.root.--root-background-foreground {
	background-color: $foregroundColor; }

.root.--root-background-footer {
	background-color: $backgroundColorFooter; }

.root.--root-background-footer-darker {
	background-color: $backgroundColorFooterDarker; }

.root.--root-background-diagonal-gradient {
	background-image: linear-gradient(110deg, #{$backgroundColor} 20%, #{$backgroundColorDarker} 130%); }

.root.--root-background-horizontal-gradient {
	background-image: linear-gradient(90deg, #{$backgroundColor} 0%, #{$backgroundColorDarker} 100%); }

.root.--root-background-blur {
	position: relative;

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba($backgroundColor, 0.8);
		background-repeat: cover;
		backdrop-filter: blur(4px); } }

.root.--root-position-sticky-top {
	position: sticky;
	top: 0; }

.root.--root-position-sticky-bottom {
	position: sticky;
	bottom: 0; }

.root.--content-justify-center .content {
	justify-content: center; }

.root.--content-justify-flex-start .content {
	justify-content: flex-start; }

.root.--content-justify-flex-end .content {
	justify-content: flex-end; }

.root.--content-align-center .content {
	align-items: center; }

.root.--content-align-flex-start .content {
	align-items: flex-start; }

.root.--content-align-flex-end .content {
	align-items: flex-end; }

.root.--content-padding-horizontal-normal {
	@include notFullHD {
		padding-left: $horizontalSpacing;
		padding-right: $horizontalSpacing; }


	@include fullHD {
		padding-left: $horizontalSpacingMax;
		padding-right: $horizontalSpacingMax; } }

.root.--content-padding-left-normal {
	@include notFullHD {
		padding-left: $horizontalSpacing; }


	@include fullHD {
		padding-left: $horizontalSpacingMax; } }

.root.--content-padding-right-normal {
	@include notFullHD {
		padding-right: $horizontalSpacing; }


	@include fullHD {
		padding-right: $horizontalSpacingMax; } }

.root.--content-padding-horizontal-big {
	@include notFullHD {
		padding-left: calc(#{$horizontalSpacing} * #{$phi});
		padding-right: calc(#{$horizontalSpacing} * #{$phi}); }


	@include fullHD {
		padding-left: calc(#{$horizontalSpacingMax} * #{$phi});
		padding-right: calc(#{$horizontalSpacingMax} * #{$phi}); } }

.root.--content-padding-horizontal-biggest {
	@include notFullHD {
		padding-left: calc(#{$horizontalSpacing} * (3 * #{$phi}));
		padding-right: calc(#{$horizontalSpacing} * (3 * #{$phi})); }


	@include fullHD {
		padding-left: calc(#{$horizontalSpacingMax} * (3 * #{$phi}));
		padding-right: calc(#{$horizontalSpacingMax} * (3 * #{$phi})); } }

.root.--content-padding-left-big {
	@include notFullHD {
		padding-left: calc(#{$horizontalSpacing} * #{$phi}); }


	@include fullHD {
		padding-left: calc(#{$horizontalSpacingMax} * #{$phi}); } }

.root.--content-padding-right-big {
	@include notFullHD {
		padding-right: calc(#{$horizontalSpacing} * #{$phi}); }


	@include fullHD {
		padding-right: calc(#{$horizontalSpacingMax} * #{$phi}); } }

.root.--content-padding-left-biggest {
	@include notFullHD {
		padding-left: calc(#{$horizontalSpacing} * (3 * #{$phi})); }


	@include fullHD {
		padding-left: calc(#{$horizontalSpacingMax} * (3 * #{$phi})); } }

.root.--content-padding-right-biggest {
	@include notFullHD {
		padding-right: calc(#{$horizontalSpacing} * (3 * #{$phi})); }


	@include fullHD {
		padding-right: calc(#{$horizontalSpacingMax} * (3 * #{$phi})); } }

.root.--content-padding-horizontal-small {
	@include notFullHD {
		padding-left: calc(#{$horizontalSpacing} / #{$phi});
		padding-right: calc(#{$horizontalSpacing} / #{$phi}); }


	@include fullHD {
		padding-left: calc(#{$horizontalSpacingMax} / #{$phi});
		padding-right: calc(#{$horizontalSpacingMax} / #{$phi}); } }

.root.--content-padding-right-small {
	@include notFullHD {
		padding-right: calc(#{$horizontalSpacing} / #{$phi}); }


	@include fullHD {
		padding-right: calc(#{$horizontalSpacingMax} / #{$phi}); } }

.root.--content-padding-left-small {
	@include notFullHD {
		padding-left: calc(#{$horizontalSpacing} / #{$phi}); }


	@include fullHD {
		padding-left: calc(#{$horizontalSpacingMax} / #{$phi}); } }

.root.--content-margin-vertical-big {
	margin-top: calc(#{$verticalSpacing} * #{$phi});
	margin-bottom: calc(#{$verticalSpacing} * #{$phi}); }

.root.--content-margin-top-big {
	margin-top: calc(#{$verticalSpacing} * #{$phi}); }

.root.--content-margin-vertical-normal {
	margin-top: $verticalSpacing;
	margin-bottom: $verticalSpacing; }

.root.--content-margin-top-normal {
	margin-top: $verticalSpacing; }

.root.--content-margin-vertical-small {
	margin-top: calc(#{$verticalSpacing} / #{$phi});
	margin-bottom: calc(#{$verticalSpacing} / #{$phi}); }

.root.--content-margin-top-small {
	margin-top: calc(#{$verticalSpacing} / #{$phi}); }

.root.--content-padding-vertical-small {
	padding-top: calc(#{$verticalSpacing} / #{$phi});
	padding-bottom: calc(#{$verticalSpacing} / #{$phi}); }

.root.--content-margin-top-tiny {
	margin-top: calc(#{$verticalSpacing} / (2 * #{$phi})); }

.root.--content-padding-vertical-tiny {
	padding-top: calc(#{$verticalSpacing} / (2 * #{$phi}));
	padding-bottom: calc(#{$verticalSpacing} / (2 * #{$phi})); }

.root.--content-padding-vertical-tiny {
	padding-top: calc(#{$verticalSpacing} / (2 * #{$phi}));
	padding-bottom: calc(#{$verticalSpacing} / (2 * #{$phi})); }

.root.--content-padding-vertical-normal {
	padding-top: $verticalSpacing;
	padding-bottom: $verticalSpacing; }

.root.--content-padding-vertical-big {
	padding-top: calc(#{$verticalSpacing} * #{$phi});
	padding-bottom: calc(#{$verticalSpacing} * #{$phi}); }

.root.--content-padding-top-big {
	padding-top: calc(#{$verticalSpacing} * #{$phi}); }

.root.--content-padding-bottom-big {
	padding-bottom: calc(#{$verticalSpacing} * #{$phi}); }

.root.--content-padding-top-normal {
	padding-top: $verticalSpacing; }

.root.--content-padding-bottom-normal {
	padding-bottom: $verticalSpacing; }

.root.--content-padding-top-small {
	padding-top: calc(#{$verticalSpacing} / #{$phi}); }

.root.--content-padding-bottom-small {
	padding-bottom: calc(#{$verticalSpacing} / #{$phi}); }

.root.--content-padding-top-menu {
	padding-top: $menuHeight; }

@mixin gap($factor: 0) {
	$scaleFactor: max(1, $factor * $phi);
	$hgap: calc(#{$horizontalSpacing} / #{$scaleFactor});
	$vgap: calc(#{$verticalSpacing} / #{$scaleFactor});

	overflow: hidden;

	> .content {
		transform: translate(calc(#{$hgap} / -2), calc(#{$vgap} / -2));
		width: calc(100% + #{$hgap});
		margin-bottom: calc(#{$vgap} * -1); }

	> .content > .cardholder {
		margin: calc(#{$vgap} / 2) calc(#{$hgap} / 2); } }

@mixin mobileGap($factor: 0) {
	$scaleFactor: max(1, $factor * $phi);
	$vgap: calc(#{$verticalSpacing} / #{$scaleFactor});

	> .content {
		transform: translate(0, calc(#{$vgap} / -2));
		width: 100%; }

	> .content > .cardholder {
		margin: calc(#{$vgap} / 2) 0; } }

.root.--content-gap-big {
	@include gap; }

.root.--content-gap-normal {
	@include gap(1); }

.root.--content-gap-small {
	@include gap(2); }

.root.--content-display-stacked {
	> .content > * {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		min-height: 100%; } }

.root.--content-display-stacked-behind {
	> .content > *:not(:last-child) {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		min-height: 100%; } }

@include phone {
	.row,
	.column,
	.root.--content-wrap-normal .content {
		flex-direction: column; }

	.root.--content-wrap-reverse .content,
	.root:not(.--content-wrap-normal) .row-reverse,
	.root:not(.--content-wrap-normal) .column-reverse {
		flex-direction: column-reverse; }

	.root.--content-gap-big {
		@include mobileGap; }

	.root.--content-gap-normal {
		@include mobileGap(1); }

	.root.--content-gap-small {
		@include mobileGap(2); } }
