@import "../../assets/styles/mixins";
@import "../../assets/styles/layout";
@import "../../assets/styles/colors";

.title {
	margin-top: 0;
	margin-bottom: 0.3em;

	&:not(.small) {
		@include notFullHD {
			font-size: calc(2em + #{$h1GrowFactor}); }


		@include fullHD {
			font-size: calc(2em + #{$h1GrowFactorMax}); } }

	&.small {
		@include notFullHD {
			font-size: calc(1.2em + #{$h2GrowFactor}); }


		@include fullHD {
			font-size: calc(1.2em + #{$h2GrowFactorMax}); } } }

.root.--title-decoration-underlined {
	.title {
		position: relative;
		display: inline;

		&::after {
			display: block;
			content: "";
			position: absolute;
			top: calc(100% + 0.2em);
			left: 0;
			right: 0;
			height: 1px;
			background: $themeColor; } }

	.title-container + * {
		margin-top: 3em; } }

.root.--content-text-align-center {
	.title,
	.summary {
		text-align: center; } }

.root.--title-text-align-center {
	.title {
		text-align: center; } }

.root.--summary-text-align-center {
	.summary {
		text-align: center; } }

.root.--title-decoration-underlined.--content-text-align-center,
.root.--title-decoration-underlined.--title-text-align-center {
	.title-container {
		text-align: center; } }

