@import '../../assets/styles/layout';
@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.menu {
	display: flex;
	align-items: center;
	position: relative;

	height: $menuHeight;
	margin-bottom: calc(#{$menuHeight} * -1);
	// For aside menu with social widgets
	// margin-left: $menuHeight
	box-sizing: border-box;

	z-index: 10;
	@include notFullHD {
		padding: 0 $horizontalSpacing; }
	@include fullHD {
		padding: 0 $horizontalSpacingMax; }

	> *:not(.right-side) {
		min-width: 0; }

	// For aside menu with social widgets
	// ~ *
 }	// 	margin-left: $menuHeight

.logo {
	display: inline-block;
	flex: 0 1 200px;
	height: 30px;
	text-align: left;

	img {
		width: 100%;
		height: 30px;
		object-fit: contain;
		object-position: left; } }

.spacer {
	flex: 1 1 auto;
	min-width: 20px; }

.site-links {
	flex: 0 4 auto;
	min-width: 0;
	display: inline-flex;
	align-items: center;
	overflow: hidden;
	opacity: 0;
	margin-right: 20px;

	&.is-overflowing {
		opacity: 0;
		pointer-events: none; }

	&.is-visible {
		opacity: 1;
		pointer-events: all; } }

.right-side {
	display: inline-flex;
	position: relative;
	flex: 0 1 auto;
	justify-content: flex-end; }

.link {
	display: inline-flex;
	align-items: center;
	height: 34px;
	padding-left: 5px;
	padding-right: 5px;

	font-family: $menuLinksFont;
	color: $menuTextColor;
	text-decoration: none;
	white-space: nowrap;

	border-bottom: 1px solid transparent;

	+ .link {
		margin-left: 20px; }

	&.is-selected {
		color: $menuTextColorSelected;
		border-bottom-color: $menuTextColorSelected; } }

.menu .action-button {
	min-width: 90px;
	max-width: 50%; }

.menu .dropdown {
	min-width: 90px;
	margin-left: 100px;
	+ .action-button {
		position: absolute;
		left: 0; } }

.aside {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: $menuHeight;
	z-index: 10;
	border-right: 1px solid $menuBackgroundColor;
	z-index: 11;
	pointer-events: none; }

.menu-button {
	display: flex;
	align-items: center;
	justify-content: center;

	width: $menuHeight;
	height: $menuHeight;

	box-sizing: border-box;

	background: $menuBackgroundColor;
	border: none;
	outline: none;

	cursor: pointer;
	pointer-events: initial;
	transition: height 200ms 200ms ease-in; }


@keyframes transform-top-arrow {
	0% {
		right: 0;
		bottom: calc(100% + 4px);
		width: calc(100% + 5px);
		transform: rotateZ(0); }
	40% {
		right: 50%;
		bottom: calc(100% + 4px);
		width: 50%; }
	100% {
		right: 50%;
		bottom: calc(50% + 1px);
		width: 50%;
		transform: rotateZ(-30deg); } }

@keyframes transform-bottom-arrow {
	0% {
		right: 0;
		top: calc(100% + 4px);
		width: calc(100% - 5px);
		transform: rotateZ(0); }
	40% {
		right: 50%;
		top: calc(100% + 4px);
		width: 50%; }
	100% {
		right: 50%;
		top: calc(50% + 1px);
		width: 50%;
		transform: rotateZ(30deg); } }
@keyframes detransform-top-arrow {
	0% {
		right: 50%;
		bottom: calc(50% + 1px);
		width: 50%;
		transform: rotateZ(-30deg); }
	40% {
		right: 50%;
		bottom: calc(100% + 4px);
		width: 50%; }
	100% {
		right: 0;
		bottom: calc(100% + 4px);
		width: calc(100% + 5px);
		transform: rotateZ(0); } }

@keyframes detransform-bottom-arrow {
	0% {
		right: 50%;
		top: calc(50% + 1px);
		width: 50%;
		transform: rotateZ(30deg); }
	40% {
		right: 50%;
		top: calc(100% + 4px);
		width: 50%; }
	100% {
		right: 0;
		top: calc(100% + 4px);
		width: calc(100% - 5px);
		transform: rotateZ(0); } }

.burger-icon {
	position: relative;
	height: 2px;
	width: calc(#{$menuHeight} - 10px);
	min-width: 10px;
	max-width: 17px;
	background: $textColor;
	border-radius: 3px;

	&::before {
		content: '';
		display: inline-block;
		position: absolute;
		bottom: calc(100% + 4px);
		right: 0;
		width: calc(100% + 5px);
		height: inherit;

		border-radius: inherit;
		background: inherit; }

	&::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: calc(100% + 4px);
		right: 0;
		width: calc(100% - 5px);
		height: inherit;

		border-radius: inherit;
		background: inherit; } }

.menu-background {
	position: fixed;
	top: 0;
	bottom: 0;
	left: $menuHeight;
	width: 0;
	background: $menuBackgroundColor;
	color: $menuTextColor;

	transition: width 300ms ease-out; }

@keyframes open-menu {
	from {
		opacity: 0; }
	to {
		opacity: 1; } }

.menu-content {
	opacity: 0;
	@include notFullHD {
		padding: $verticalSpacing $horizontalSpacing; }
	@include fullHD {
		padding: $verticalSpacing $horizontalSpacingMax; }

	animation: open-menu 600ms 600ms linear forwards; }

.aside.is-open {
	pointer-events: initial;
	.burger-icon {
		&::before {
			animation: transform-top-arrow 400ms 600ms ease-out forwards; }
		&::after {
			animation: transform-bottom-arrow 400ms 400ms ease-out forwards; } }

	.menu-button {
		height: 100%;
		transition: height 200ms ease-in; }
	.menu-background {
		width: 100%;
		transition: width 300ms 200ms ease-out; } }


.aside.is-close {
	.burger-icon {
		&::before {
			right: 50%;
			bottom: calc(50% + 1px);
			width: 50%;
			transform: rotateZ(-30deg);
			animation: detransform-top-arrow 400ms 600ms ease-out forwards; }
		&::after {
			right: 50%;
			top: calc(50% + 1px);
			width: 50%;
			transform: rotateZ(30deg);
			animation: detransform-bottom-arrow 400ms 800ms ease-out forwards; } } }


@include phone {
	.menu {
		height: $mobileMenuHeight;
		// For aside menu with social widgets
		// margin-left: $mobileMenuHeight
		// max-width: calc(100% - #{$mobileMenuHeight})
		margin-bottom: calc(#{$mobileMenuHeight} * -1);

		~ * {
			margin-left: 0; }

		.right-side {
			flex: 1 1 auto; }

		.dropdown {
			max-width: 110px;
			min-width: 110px;
			margin-left: 70px; }

		.action-button {
			min-width: 60px;
			padding: 4px; } }

	.aside {
		width: $mobileMenuHeight;
		border-right-color: transparent; }

	.logo {
		height: 28px;
		img {
			height: 28px;
			max-width: 100%; } }

	.menu-button {
		width: $mobileMenuHeight;
		height: $mobileMenuHeight; }

	.link {
		height: calc(#{$mobileMenuHeight} - 20px); }

	.menu-background {
		left: $mobileMenuHeight; } }

