.root {
	position: relative;
	height: 100vh;
	overflow: hidden; }

.background-image {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat; }


@keyframes rotate {
	from {
		transform: rotateY(-58deg) rotateX(24deg) rotateZ(0deg) translateX(0); }

	to {
		transform: rotateY(0deg) rotateX(0deg) rotateZ(0deg) translateX(-50%); } }

.svg-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	svg {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		pointer-events: none;
		// transform: translateZ(0)
		// backface-visibility: hidden

		* {
			// backface-visibility: hidden
			// will-change: fill, tranform, opacity, dash-array, dash-offset
			transtion: all 100ms linear; } } }

