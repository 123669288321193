@import "../../assets/styles/mixins";

.root {
	display: flex;
	justify-content: space-between;
	align-items: center; }

.title {
	margin-top: 0;
	&:only-child {
		margin-bottom: 0; } }

@include phone {
	.root {
		flex-direction: column;
		align-items: flex-start; }
	.button {
		margin-top: 1em;
		margin-bottom: 1em; } }
